<template>
    <div>
        <iframe :src="iframeUrl" title="Card Monitoring" frameborder="0" class="w-full h-screen"></iframe>
    </div>
</template>

<script>
    export default {
        data() {
            
            return {
                iframeUrl: process.env.VUE_APP_TRANSACTION_MONITORING_URL
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>